* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    width: 100%;
    font-family: 'Arial', sans-serif;
    background-color: #ffffff;
  }
  
  .reservation-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 100px; /* Aumentado para garantir que o h1 não seja sobreposto pelo logo */
    background-color: #fff;
  }
  
  h1 {
    
    font-family: 'Arial', sans-serif; /* Mesma fonte, maior tamanho */
    font-size: 20px; /* Aumentado o tamanho da fonte */
    color: #333;
    line-height: 24px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    text-align: center;
    width: 100%;
  }
  
  
  .reservation-form {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .form-group {
    margin-bottom: 10px;    
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
  }
  
  /* Estilo dos selects e inputs */
  select, input, textarea {
    width: 100%;
    padding: 10px; /* Reduzido de 15px para 10px */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  
  select:focus, input:focus {
    border-color: #4CAF50; /* Borda verde quando o campo está em foco */
    outline: none; /* Remove o contorno azul padrão */
  }
  
  .submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 15px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Ajustado para ocupar 90% da largura */
    max-width: 600px; /* Limitar a largura máxima do botão */
    margin: 0 auto; /* Centralizar o botão */
    position: fixed;
    bottom: 10px; /* Ajustar para ficar um pouco acima do final da tela */
    left: 50%;
    transform: translateX(-50%);
}

.submit-button:hover {
    background-color: #45a049;
}
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Banner */
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: url('banner.jpg'); /* Substitua pelo caminho da sua imagem */
    background-size: cover;
    background-position: center;
  }
  
  /* Logo com borda fina em cinza e fundo branco */
  .logo {
    position: fixed;
    top: 40px; /* Para fazer o logo ultrapassar um pouco o banner */
    left: 10px; /* Pequena margem à esquerda */
    width: 80px;
    height: 80px;
    border-radius: 8px;
    border-color: #ccc;    
    border: 1px solid #ccc;
    background-color: #fff;
    background-image: url('logo.png'); /* Substitua pelo caminho da sua imagem */
    background-size: cover;
    background-position: center;
    z-index: 10; /* Para garantir que o logo fique por cima de outros elementos */
 }

 .back-button {
    background-color: transparent;
    color: #4CAF50;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin: 5px 0 0 250px;
    display: flex;
    align-items: left;
    width: 100%;
  }
  
  .back-button:hover {
    color: #45a049;
  }
  
  .aviso-observacoes {
    background-color: #ffdddd;
    color: #d8000c;
    border: 1px solid #d8000c;
    padding: 8px;
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
  }

  .reservation-info {
    background-color: #f8f8f8;
    padding: 5px;
    border-left: 4px solid #007bff;
    margin-bottom: 15px;
    font-size: 14px;
    color: #333;
  }
  
  .reservation-info p {
    margin: 5px 0;
  }
  
  select {
    -webkit-appearance: none; /* Remove a aparência padrão do iPhone */
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ccc; /* Borda fina */
    border-radius: 4px; /* Arredondar os cantos */
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23666" d="M2 0L0 2h4zM0 3l2 2 2-2z"/></svg>'); 
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    cursor: pointer; /* Mostrar que é clicável */
    color: #333; /* Ajustar a cor do texto para o desejado */

    white-space: nowrap; /* Evita quebra de linha */
    overflow: hidden; /* Esconde o excesso de texto */
    text-overflow: ellipsis; /* Adiciona reticências ao texto que ultrapassar */
  }
  
  /* Adicionar foco ao select */
  select:focus {
    border-color: #4CAF50; /* Cor da borda ao focar */
    outline: none; /* Remover o outline padrão */
    color: #000; /* Cor do texto no foco */
  }

  
  @media screen and (max-width: 600px) {
    .reservation-form {
      padding: 10px;
    }
  }

